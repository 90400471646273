#app {
	height: 100%;
}
html,
body {
	position: relative;
	height: 100%;
}

body {
	background: #eee;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 14px;
	color: #000;
	margin: 0;
	padding: 0;
}

.swiper {
	width: 100%;
	height: 100%;
}

.rightBoxSwiper .roadmap2 {
	background: url("../assets/roadmap.png") no-repeat center;
	background-size: 100% 100%;
	height: 538px;
	border-radius: 5px;
	box-sizing: border-box;
	padding: 32px;
	width: 336px;
}
.rightBoxSwiper .roadmap2.active {
	background: url("../assets/roadmap-active.png") no-repeat center;
	color: #fff;
	border-radius: 24px;
}
/* .roadmap2.active {
	color: #fff;
} */

.roadmaph2:after {
	content: "";
	width: 16px;
	height: 3px;
	background: rgba(255, 0, 170, 1);
	display: block;
	position: absolute;
	left: 4%;
	margin-left: -8px;
	top: 48px;
	border-radius: 3px;
}
.roadmaph3 {
	line-height: 2;
	margin-bottom: 16px;
}
.swiper-button-prev,
.swiper-button-next {
	display: none !important;
}
