@font-face {
	font-family: DDIN;
	src: url("../assets/D-DIN-Bold.otf");
}

.Databg {
	background: url("../assets/dataBg.png") no-repeat center;
	background-size: 100% 100%;
}
.inner {
	justify-content: space-around;
	align-items: center;
	display: flex;
	height: 320px;
}
.numBox {
	width: 421px;
	text-align: center;
}
.numText {
	font-size: 64px;
	color: #fff;
	font-family: "DDIN";
	margin-bottom: 16px;
}
.nutText {
	text-align: center;
	font-size: 20px;
}
