:root {
	--linearGradient: linear-gradient(
		123.68deg,
		rgba(255, 0, 170, 1) 0%,
		rgba(0, 170, 255, 1) 100%
	);
}

* {
	margin: 0;
}
.App {
	min-width: 1200px;
	color: #fff;
	background-color: #000000;
}
.small14 {
	font-size: 14px;
}
.small12 {
	font-size: 12px;
}

.banner {
	width: 100%;
	height: 960px;
	// height: 0;
	// padding-bottom: 50%;
	background: url("./assets/dinosaur.gif") no-repeat center;
	background-size: 100%;
}
.banner img {
	width: 100%;
}
.header {
	left: 0;
	top: 0px;
	position: fixed;
	height: 80px;
	box-sizing: border-box;
	background-color: #000000;
	align-items: center;
	display: flex;
	z-index: 9999;
	justify-content: center;
	width: 100%;
}
.centerHeader {
	width: 1200px;
	justify-content: space-between;
	align-items: center;
	display: flex;
	flex-wrap: nowrap;
	gap: 20px;
}
.header.active {
	backdrop-filter: blur(16px);
	background: rgba(0, 170, 255, 0.04);
	background-blend-mode: normal;
	backdrop-filter: blur(16px);
	mix-blend-mode: normal;
}
a {
	text-decoration: none;
	color: #ffffff;
}
.logo {
	width: 201px;
	height: 40px;
	overflow: hidden;
}
.cenerNav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	padding-top: 10px;
}
.textNav {
	font-size: 16px;
	margin-right: 80px;
	transition: all 0.3s ease;
	height: 32px;
	cursor: pointer;
}
.textNav:last-child {
	margin-right: 0;
}
.but {
	width: 48px;
	height: 48px;
	margin-right: 20px;
	cursor: pointer;
}

.text {
	height: 48px;
	background: var(--linearGradient);
	border-radius: 24px;
	text-align: center;
	line-height: 48px;
	cursor: pointer;
	font-size: 16px;
	padding: 0 20px;
}
.launch {
	display: flex;
	min-width: 10%;
}
.launch .text,
.launch img {
	opacity: 0.85;
	transition: all 0.3s ease;
}
.launch .text:hover,
.launch img:hover {
	opacity: 1;
}
.bannertext {
	width: 100%;
	padding-top: 346px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.hd {
	margin-bottom: 32px;
	font-size: 120px;
	font-weight: 800;
}
.bd1,
.bd2 {
	font-size: 24px;
}
.bd2 {
	margin-top: 16px;
}
.blue {
	color: rgba(0, 170, 255, 1);
}
.data,
.data img {
	width: 100%;
	display: inherit;
}
.cardinfo,
.cardinfobox,
.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.cardinfo {
	width: 100%;
	background: rgba(10, 10, 25, 0.92);
	padding-bottom: 120px;
}
.text1 {
	margin-top: 127px;
	color: rgba(255, 0, 170, 1);
	font-size: 24px;
	font-weight: 500;
}
.text2 {
	font-size: 48px;
	padding-top: 16px;
	padding-bottom: 64px;
	font-weight: 800;
}
.text3 {
	width: 160px;
	font-size: 16px;
	line-height: 48px;
	background: transparent url("./assets/button.png") no-repeat center;
	background-size: 100%;
	text-align: center;
	cursor: pointer;
	border-radius: 24px;
	transition: all 0.3s ease;
	z-index: 1;
}
.text3:hover {
	background: var(--linearGradient);
}
.centerbox {
	display: flex;
	justify-content: center;
	align-items: baseline;
	flex-direction: row;
	width: 1200px;
	overflow: hidden;
}

.icon {
	width: 320px;
	margin-right: 40px;
	img {
		width: 100%;
	}
}
.icon:last-child {
	margin-right: 0;
}

.icon p {
	margin-top: -40px;
	line-height: 2;
}
.mt80 {
	margin-top: 80px;
}
.cardinfotext {
	background: url("./assets/Bg_Rlues_3.png") no-repeat center;
	background-size: contain;
	text-align: center;
	width: 376px;
	height: 526px;
	border-radius: 24px;
	transition: all 0.3s ease;
}
.cardinfotext:nth-child(2) {
	margin: 0 48px;
}
.cardinfotext:hover {
	background: url("./assets/card_Bg.png") no-repeat center;
	cursor: pointer;
	color: #fff;
	background-size: contain;
}
.cardinfotext:hover .h1 {
	color: #fff;
}
.cardinfotext:hover .h1::after {
	background-color: #fff;
}
.selectactive,
.textNav:hover,
.red {
	color: rgba(255, 0, 170, 1);
	position: relative;
}
.opacity5 {
	opacity: 0.5;
}
.selectactive:after,
.textNav:hover::after {
	transition: all 0.3s ease;
	position: absolute;
	width: 12px;
	margin-left: -6px;
	left: 50%;
	bottom: 6px;
	content: "";
	background-color: rgba(255, 0, 170, 1);
	height: 3px;
	border-radius: 2px;
	box-shadow: 0px 0px 16px 1px;
}
.h1 {
	margin-top: 48px;
	margin-bottom: 32px;
	font-size: 24px;
	font-weight: bold;
	color: #ff00aa;
	line-height: 32px;

	position: relative;
}
.h3 {
	line-height: 40px;
}

.mapbig {
	font-size: 48px;
	width: 480px;
	font-weight: 800;
}
.maptext {
	margin-bottom: 160px;
	padding-right: 80px;
	text-align: left;
	//word-break: break-all;
	line-height: 2;
}
.iconimg {
	width: 100%;
}
.iconimg div {
	width: 40px;
	height: 40px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
	display: inline-block;
}
.slide_btn_prev {
	background-image: url("./assets/icon_left.png");
	margin-right: 15px;
	transition: all 0.3s ease;
}
.slide_btn_next {
	background-image: url("./assets/icon_right1.png");
	transition: all 0.3s ease;
}
.slide_btn_prev.active,
.slide_btn_prev:hover {
	background-image: url("./assets/icon_left1.png");
}
.slide_btn_next.active,
.slide_btn_next:hover {
	background-image: url("./assets/icon_right.png");
}
.roadmaph1 {
	color: rgba(255, 0, 170, 1);
}
.active .roadmaph2:after {
	background-color: #fff;
}
.active .roadmaph1 {
	color: #fff;
}
.roadmaph2 {
	font-size: 28px;
	position: relative;
	margin-bottom: 48px;
	font-weight: 600;
	margin-top: 4px;
}
.cardinfobox p.h1:after {
	content: "";
	width: 16px;
	height: 3px;
	background: rgba(255, 0, 170, 1);
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -8px;
	top: 48px;
	border-radius: 3px;
	box-shadow: 0px 0px 16px 1px;
}
.centerMap > .roadmap2 {
	width: 480px;
	height: 538px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.roadmap {
	flex-direction: row;
	display: flex;
	padding-top: 120px;
	justify-content: center;
	width: 100%;
	overflow: hidden;
}

.join {
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url("./assets/Banner_Bg.png");
	background-size: cover;
}
.jointext1 {
	font-size: 48px;
	font-weight: 800;
}
.jointext2 {
	font-size: 16px;
	padding-top: 16px;
	padding-bottom: 64px;
}
.joinright {
	width: 400px;
	height: 400px;
	margin-left: 200px;
}
.logo_join {
	width: 400px;
	height: 400px;
}
.bottombox {
	margin-top: 120px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}
.org {
	width: 1200px;
	height: 320px;
	align-self: center;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
}
.org_text {
	font-size: 48px;
	font-weight: 800;
}
.org_img {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.org_img div {
	// width: 276px;
	width: 224px;
	height: 88px;
	background: url(./assets/Bg.png) no-repeat;
	background-size: 100% 100%;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
}

.org_img div:nth-child(-n + 5) {
	margin-bottom: 32px;
}
.org_img div:nth-child(5n) {
	margin-right: 0;
}
.org_img div img {
	margin-right: 16px;
}
.bottom-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 120px;
	gap: 40px;
}
.bottom-icon .icon-wrap {
	width: 42px;
	height: 42px;
	cursor: pointer;
	transition: all 0.3s ease;
	display: flex;
	background-size: cover;
}

.discord-logo {
	background-image: url(./assets/discord.svg);
}
.twitter-logo {
	background-image: url(./assets/icon_twitter.svg);
}
.telegram-logo {
	background-image: url(./assets/icon_telegram.svg);
}
.github-logo {
	background-image: url(./assets/icon_github.svg);
}
.email-logo {
	background-image: url(./assets/icon_email.svg);
}

.discord-logo:hover {
	background-image: url(./assets/discord_fill.svg);
}
.twitter-logo:hover {
	background-image: url(./assets/icon_twitter_fill.svg);
}
.telegram-logo:hover {
	background-image: url(./assets/icon_telegram_fill.svg);
}
.github-logo:hover {
	background-image: url(./assets/icon_github_fill.svg);
}
.email-logo:hover {
	background-image: url(./assets/icon_email_fill.svg);
}

.bottom-icon img {
	width: 40px;
	height: 40px;
	cursor: pointer;
	margin-right: 40px;
	transition: all 0.3s ease;
}
.end {
	padding: 30px 0;
}
.rightBox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	/* overflow: hidden; */
	height: 538px;
	flex: 1;
	position: relative;
}
.centerMap {
	width: 1200px;
	height: 538px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}
.rightBoxSwiper {
	overflow: hidden;
	position: absolute;
	width: 160%;
}

.cardinfo-bg {
	background-image: url(./assets/Bg_Rlues.png);
	background-size: cover;
}

.container {
	width: 1200px;
	margin: 0 auto;
}

.team {
	padding-top: 120px;
	header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 64px;
		h4 {
			width: 128px;
			height: 72px;
			font-size: 48px;
			font-weight: bold;
			color: #ffffff;
			line-height: 72px;
		}
		p {
			width: 800px;
			height: 56px;
			font-size: 14px;
			font-weight: 400;
			color: #ffffff;
			line-height: 28px;
			text-align: center;
		}
	}

	.card {
		width: 276px;
		height: 370px;
		overflow: hidden;
		padding: 17px;
		box-sizing: border-box;
		position: relative;
		text-align: center;
		background: url(./assets/Bg_Team.png) no-repeat center;
		background-size: contain;
	}

	.card-1 .top-bg {
		background: url(./assets/png1.png) no-repeat center;
	}
	.card-2 .top-bg {
		background: url(./assets/png2.png) no-repeat center;
	}
	.card-3 .top-bg {
		background: url(./assets/png3.png) no-repeat center;
	}
	.card-4 .top-bg {
		background: url(./assets/png4.png) no-repeat center;
	}

	.top-bg {
		width: 244px;
		height: 80px;
		overflow: hidden;
	}

	.avatar-img {
		position: absolute;
		left: 50%;
		top: 63px;
		margin-left: -38px;
	}

	.info {
		text-align: center;
		margin-top: 74px;
		p {
			width: 100%;
		}
	}
	.user-name {
		width: 184px;
		height: 28px;
		font-size: 20px;
		font-family: SourceHanSansSC-Bold, SourceHanSansSC;
		font-weight: bold;
		color: #ffffff;
		line-height: 28px;
		margin-bottom: 8px;
	}
	.title {
		width: 101px;
		height: 22px;
		font-size: 14px;
		font-family: SourceHanSansSC-Medium, SourceHanSansSC;
		font-weight: 500;
		color: #ff00aa;
		line-height: 22px;
		margin-bottom: 24px;
	}
	.desc {
		width: 298px;
		height: 56px;
		font-size: 14px;
		font-family: SourceHanSansSC-Regular, SourceHanSansSC;
		font-weight: 400;
		color: #ffffff;
		line-height: 28px;
	}

	section {
		padding-bottom: 119px;
		display: flex;
		gap: 39px;
	}
}
